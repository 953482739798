
import { DownloadUtils } from "@/shared/utils/DownloadUtils";
import { forkJoin } from "rxjs";
import { Prop, Component, Vue, Watch } from "vue-property-decorator";
import CustomDialog from "../CustomDialog/CustomDialog.vue";
import "./DownloadReports.scss";
@Component({
  name: "DownloadReports",
  components: {
    CustomDialog,
  },
})
export default class DownloadReports extends Vue {
  @Prop() filePathArr;
  @Prop() userId;
  @Prop() executionId;
  @Prop() itemId;

  showDialog = true;
  showFilesForDownload = false;

  selectedFilesForDownload = [];
  fileSizeInfoArr: any[] = [];
  fileDownloadLoading = false;
  showError = false;
  errorMsg = "";
  selectAllFiles = false;

  @Watch("selectedFilesForDownload")
  setSelectAll(value: any): void {
    this.selectAllFiles = value.length === this.filePathArr.length;
  }

  getfileName(filePath: string): string {
    if (filePath.length > 0) {
      const fileName = filePath.split("/");
      return fileName[fileName.length - 1];
    }
    return "";
  }

  getfileSize(filePath: string): string {
    if (filePath === undefined) return "";
    return this.fileSizeInfoArr.find((item) => item.filePath === filePath)
      .fileSize;
  }

  handleCheckAllChange(val: boolean): void {
    this.selectedFilesForDownload = val ? this.filePathArr : [];
  }

  displayErrorReport(errorMsg: string): void {
    this.showError = true;
    this.errorMsg = errorMsg;
  }

  async created(): Promise<any> {
    const fileNotFoundforDownload =
      this.filePathArr.length === 0 || this.filePathArr[0].length === 0;
    if (fileNotFoundforDownload)
      return this.displayErrorReport("No file paths available for download");
    this.setFilePathSizeInfo();
  }

  /**
   * makes http HEAD requests to get the filesize of all the files to be shown in popup
   * @returns Promise
   */
  async setFilePathSizeInfo(): Promise<any> {
    this.fileDownloadLoading = true;
    const fileSizeObs$ = DownloadUtils.getFileSizeforFilePaths(
      this.filePathArr
    );
    fileSizeObs$.subscribe(
      (filesResponse) => {
        if (filesResponse?.data.code === "1") {
          filesResponse.data.data.map((res) => {
            this.fileSizeInfoArr.push({
              filePath: res.filePath,
              fileSize: this.getFileSizeinKilobyte(+res.fileSize),
            });
          });
        }
        this.fileDownloadLoading = false;
        this.showFilesForDownload = this.fileSizeInfoArr.length > 0;
      },
      (error) => {
        console.log(error);
        this.fileDownloadLoading = false;
        this.showError = true;
        this.errorMsg = "Error in fetching reports";
      }
    );
  }

  /**
   * utility function to convert the filesize in bytes to kilobytes
   * @param { number} filesize: filesize in bytes
   * @returns string
   */
  getFileSizeinKilobyte(fileSize: number): string {
    return Math.ceil(fileSize / 1024) + "kb";
  }

  /**
   * Function to close the dialog popup
   * @returns void
   */
  closeFileDownload(): void {
    this.showDialog = false;
    this.$emit("closeDownloadReports", false);
  }

  /**
   * Function to confirm and download the selected files
   * @returns void
   */
  confirmFileDownload(): void {
    const fileObs$ = DownloadUtils.getDownloadFileDataObs(
      this.userId,
      this.selectedFilesForDownload
    );
    const itemID = this.$route.query?.id || this.itemId;
    const executionID = this.$route.query?.executionId || this.executionId;
    const zipFileName = `${itemID}_${executionID}`;
    forkJoin(fileObs$).subscribe(
      (blobs) => {
        DownloadUtils.downloadReportFiles(zipFileName, blobs);
        this.showDialog = false;
        this.$emit("closeDownloadReports", false);
      },
      (error) => {
        console.log(error);
        this.fileDownloadLoading = false;
        this.$emit("closeDownloadReports", false);
        this.showError = true;
        this.errorMsg = "Error in fetching reports";
      }
    );
  }
}
