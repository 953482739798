import JSZip from "jszip";
import { Observable, from } from "rxjs";
import { tsService } from "../services/api/trouble-shooting";

export const DownloadUtils = {
  downloadAsZip(
    zipFileName: string,
    fileNameArr: string[],
    blobDataArr: Blob[]
  ): void {
    const zip = new JSZip();
    fileNameArr.forEach((fileName, index) => {
      zip.file(`${fileName}`, blobDataArr[index]);
    });
    // zip.file(`${fileName}`, blobData);
    zip.generateAsync({ type: "blob" }).then((blob) => {
      const fileLink = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      fileLink.href = url;
      fileLink.setAttribute("download", `${zipFileName}.zip`);
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
      URL.revokeObjectURL(url);
    });
  },
  downloadAsSingleFile(fileName: string, blob: any): void {
    const fileLink = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    fileLink.href = url;
    fileLink.download = fileName;
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
    URL.revokeObjectURL(url);
  },
  downloadReportFiles(zipFileName: string, blobs: Record<string, any>[]): void {
    const blobArr: Blob[] = [];
    const fileNameArr: string[] = [];
    blobs.forEach((result) => {
      const filenameStr = result.headers["content-disposition"];
      const filename = filenameStr
        ? filenameStr.substr(filenameStr.indexOf("filename=") + 9)
        : "report";
      const blob = new Blob([result.data], {
        type: "application/octet-stream",
      });
      fileNameArr.push(filename);
      blobArr.push(blob);
    });
    if (blobArr.length > 1)
      this.downloadAsZip(zipFileName, fileNameArr, blobArr);
    else this.downloadAsSingleFile(fileNameArr[0], blobArr[0]);
  },
  getDownloadFileDataObs(
    userId: string,
    filePaths: string[]
  ): Observable<any>[] {
    return filePaths.map((path) => {
      const executionFile$ = from(
        tsService.downloadExecuteFile(userId, path).catch((e) => {
          throw new Error(e);
        })
      );
      return executionFile$;
    });
  },
  getFileSizeforFilePaths(filePaths: string[]): Observable<any> {
    return from(
      tsService.getFileSizeArrforFilepaths(filePaths).catch((e) => {
        throw new Error(e);
      })
    );
  },
};
