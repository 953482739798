
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  name: "CustomDialog",
  components: {},
})
export default class CustomDialog extends Vue {
  @Prop({ default: false }) showDialog: boolean;
  @Prop({ default: "" }) dialogTitle: any;
  @Prop({ default: "Yes" }) confirmText: string;
  @Prop({ default: "No" }) cancelText: string;
  @Prop({ default: true }) disableConfirm: boolean;

  handleClose(): void {
    this.$emit("closeDialog", true);
  }

  handleConfirm(): void {
    this.$emit("confirmDialog", true);
  }
}
